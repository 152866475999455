import React from 'react'

export const IconConacim = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="960.000000pt" height="726.000000pt" viewBox="0 0 960.000000 726.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,726.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M4052 6637 c-51 -18 -96 -37 -100 -43 -4 -6 -31 -23 -60 -38 -112
                    -57 -156 -112 -1012 -1281 -452 -616 -828 -1128 -836 -1139 -13 -15 -14 -20
                    -2 -31 7 -7 269 -191 583 -409 l570 -397 17 22 c17 20 1297 1815 1382 1938 22
                    31 45 61 51 66 7 6 38 48 68 95 31 47 69 100 84 118 15 19 30 39 32 45 2 7 7
                    14 10 17 3 3 11 12 16 22 24 40 95 137 119 163 14 15 26 32 26 36 0 11 300
                    433 311 437 5 2 9 8 9 12 0 10 219 321 233 331 4 3 9 19 10 35 l2 29 -710 2
                    -710 1 -93 -31z"/>
                    <path d="M4916 5611 c-225 -313 -409 -571 -410 -574 0 -3 382 -274 849 -602
                    468 -328 855 -602 860 -609 6 -7 278 -198 605 -424 328 -226 606 -421 617
                    -432 12 -11 24 -20 27 -20 11 0 96 -64 96 -72 0 -4 5 -8 11 -8 6 0 52 -29 104
                    -65 51 -36 96 -65 99 -65 3 0 6 306 6 680 l0 680 -80 162 c-43 90 -90 175
                    -102 190 -13 14 -354 277 -758 584 -1073 814 -1087 825 -1305 991 -110 84
                    -203 153 -206 153 -3 0 -189 -256 -413 -569z"/>
                    <path d="M1597 4294 c-4 -4 -7 -289 -7 -634 l0 -627 75 -152 c118 -238 62
                    -191 1345 -1131 602 -441 1105 -814 1117 -828 12 -15 25 -23 29 -20 3 4 183
                    267 400 585 335 493 391 579 377 588 -62 38 -1392 932 -2381 1600 -293 198
                    -554 374 -580 390 -26 17 -112 74 -192 129 -148 101 -170 113 -183 100z"/>
                    <path d="M5589 2910 c-327 -478 -773 -1132 -991 -1452 -219 -321 -398 -590
                    -398 -599 0 -9 -8 -23 -19 -30 -18 -14 -191 -261 -191 -273 0 -4 -8 -16 -18
                    -27 -11 -10 -25 -31 -32 -44 -29 -55 -38 -54 687 -55 l662 0 94 34 c223 81
                    373 198 519 407 47 68 170 243 271 389 102 146 215 308 252 360 36 52 108 156
                    160 230 51 74 127 182 168 240 41 58 102 146 136 195 34 50 89 128 122 175 33
                    47 112 162 175 257 63 95 126 185 140 200 13 15 24 35 22 45 -2 11 -232 176
                    -582 418 -319 220 -580 400 -582 400 -1 0 -269 -391 -595 -870z"/>
                    </g>
                </svg>
    )
}
