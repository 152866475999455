import React from 'react'

export const IconBlooders = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="225.000000pt" height="225.000000pt" viewBox="0 0 225.000000 225.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#FF0000" stroke="none">
                    <path d="M1093 2073 c-67 -130 -136 -260 -144 -269 -5 -6 -27 -47 -49 -90 -49
                    -97 -77 -146 -146 -251 -30 -45 -54 -89 -54 -97 0 -9 -4 -16 -10 -16 -5 0 -10
                    -5 -10 -12 0 -6 -22 -45 -49 -88 -72 -111 -158 -294 -177 -375 -23 -99 -14
                    -236 21 -335 15 -41 30 -77 34 -80 3 -3 14 -22 23 -42 42 -90 172 -210 294
                    -270 105 -53 154 -63 294 -63 143 0 201 12 316 65 94 43 109 54 191 140 74 78
                    116 144 151 242 23 65 26 87 26 198 0 105 -4 136 -24 190 -30 84 -101 220
                    -211 402 -33 54 -59 100 -59 103 0 3 -16 32 -37 63 -20 31 -54 91 -76 132 -22
                    41 -60 111 -84 155 -23 44 -73 137 -109 208 -37 70 -72 127 -79 127 -7 0 -22
                    -17 -32 -37z m78 -668 c15 -27 31 -57 35 -65 5 -8 21 -37 37 -65 17 -27 41
                    -70 55 -95 13 -25 35 -63 48 -85 12 -22 49 -92 81 -155 56 -112 58 -117 58
                    -195 0 -74 -3 -85 -40 -154 -22 -40 -53 -84 -68 -97 -16 -13 -25 -24 -21 -24
                    22 0 -44 -34 -95 -50 -103 -31 -277 -22 -322 16 -8 8 -20 14 -26 14 -9 0 -30
                    22 -93 97 -36 43 -54 110 -54 203 0 94 1 96 47 188 26 51 58 110 70 130 12 20
                    29 51 37 67 8 17 21 36 28 43 6 7 12 17 12 22 0 5 20 40 44 77 24 37 51 86 60
                    108 21 49 45 77 64 73 8 -2 27 -25 43 -53z"/>
                    </g>
                    </svg>
    )
}
