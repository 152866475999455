import React from 'react'
import { FacebookIcon } from '../iconos/FacebookIcon'
import { InstagramIcon } from '../iconos/InstagramIcon'
import { TwitterIcon } from '../iconos/TwitterIcon'

export const ListSocialNetworks = ({ facebook="", twitter="", instagram="" }) => {

    return (
        <div className='__profile_social_networks'>
            { facebook !== "" 
                && <a className='__profile_item_social' target='_blank' href={facebook}><FacebookIcon /></a>
            }
            { instagram !== "" 
                && <a className='__profile_item_social' target='_blank' href={instagram}><InstagramIcon /></a>
            }
            { twitter !== "" 
                && <a className='__profile_item_social' target='_blank' href={twitter}><TwitterIcon /></a>
            }
        </div>
    )
}
