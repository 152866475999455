import React from 'react'

export const ItemUserLoading = () => {
    return (
        <div className = "_item _item_loading">
            {/* <Avatar image = { image } name = { displayName } /> */}
            {/* <BadgeShort typeUser = 'ASC' text='ASC'/> */}
            <div className = '__item_image_loading'>
            </div>
            <div className = "info-Contact">
                <div className = "_item_name_loading">

                </div>
                <div className = "_item_text_loading" >

                </div>
                {/* <a href = 'URL'>{displayName}</a>
                <p>{textSecondary}</p> */}

            </div>
            
        </div>
    )
}
