import React,{ useEffect, useState } from 'react';

import { useChangeForm } from '../../hooks/useChangeForm'
import { useChangeData } from '../../hooks/useChangeData'
import { Input } from '../Inputs/Input'
import { InputSelect } from '../Inputs/InputSelect';
import { fetchGetCategories } from '../../services/fetchGetCategories';
import { AlertInForm } from '../alerts/AlertInForm';
import { useValidateForm } from '../../hooks/useValidateForm';
import { useUpdateUserData } from '../../hooks/useUpdateUserData';
import { useSaveData } from '../../hooks/useSaveData';
import { IconCheck } from '../iconos/IconCheck';
import { LoadingInComponent } from '../loadings/LoadingInComponent';
import { AddSocialNetwork } from './AddSocialNetwork';
import { FacebookIcon } from '../iconos/FacebookIcon';
import { InstagramIcon } from '../iconos/InstagramIcon';
import { TwitterIcon } from '../iconos/TwitterIcon';

export const ConfigDataAssociation = ( oldData )=>{
    const { displayName, cid, description, facebook, twitter, instagram } = oldData;
    const [ isSaved, setIsSaved ] = useSaveData();
    const [ inputFormValues, handdleInputChange ]= useChangeForm({ associationName:displayName, category:cid, description, facebook, twitter, instagram});
    const [ isDiferent,  setIsDiferent ] = useChangeData( inputFormValues, { displayName, cid, description, facebook, twitter, instagram } );
    const [ validForm, isValid ] = useValidateForm({associationName:false, category:false, description:false, facebook:false, twitter:false, instagram:false}, inputFormValues)
    const [categories, setCategories] = useState([])
    const [isLoading, handleSaveData ] = useUpdateUserData( setIsDiferent, setIsSaved, isValid, inputFormValues, oldData )
    useEffect(() => {
        fetchGetCategories().then( setCategories );
    }, []);
    return (
        <>
        <div className = "animate__animated animate__bounce animate__fadeIn">
            <p>Nombre de asociación:</p>
            <Input 
                name = {'associationName'}
                inputStyle = {'__input'}
                typeInput = {'text'}
                value = { inputFormValues.associationName }
                placeholder = {'Nombre de asociación'}
                onChange = { handdleInputChange }
            />
            {
                validForm.associationName 
                    && <AlertInForm 
                            styleAlert={ '__alert_error_inForm' }
                            title = { 'Error en nombre de asociación' }
                            descriptionError = { validForm.errorassociationName }
                        />
            }
            <p>Descripción:</p>
            <Input 
                name = {'description'}
                inputStyle = {'__input'}
                typeInput = {'text'}
                value = { inputFormValues.description }
                placeholder = {'Descripción'}
                onChange = { handdleInputChange }
            />
            {
                validForm.description 
                    && <AlertInForm 
                            styleAlert={ '__alert_error_inForm' }
                            title = { 'Error la descripción' }
                            descriptionError = { validForm.errordescription }
                        />
            }
            <p>Categoria actual:</p>
            <div className="__input_wrapper">
                <InputSelect
                name = {"category"}
                value ={ 0 }
                textDefault = {"Selecciona una categoria"}
                onChange = { handdleInputChange }
                keyName = {"category"}
                arrayData = { categories }
                optionDefault = { inputFormValues.category }/>
            </div>
            {
                validForm.category 
                    && <AlertInForm 
                            styleAlert={ '__alert_error_inForm' }
                            title = { 'Error en elegir la categoria' }
                            descriptionError = { validForm.errorcategory }
                        />
            }
            <p>Redes sociales:</p>
            <Input
                name = "facebook" 
                typeInput = {"text"} 
                inputStyle = {'__input __input_with_icon'} 
                placeholder = {'https://www.facebook.com/usuario'}
                InputIcon = { FacebookIcon }
                value = {inputFormValues.facebook ||""}
                onChange = {handdleInputChange}/>
            {
                validForm.facebook 
                    && <AlertInForm 
                            styleAlert={ '__alert_error_inForm' }
                            title = { 'Error en la url' }
                            descriptionError = { validForm.errorfacebook }
                        />
            }
            <Input
                name = "instagram" 
                typeInput = {"text"} 
                inputStyle = {'__input __input_with_icon'} 
                placeholder = {'https://www.instagram.com/usuario'}
                InputIcon = { InstagramIcon }
                value = {inputFormValues.instagram ||""}
                onChange = {handdleInputChange}/>
            {
                validForm.instagram 
                    && <AlertInForm 
                            styleAlert={ '__alert_error_inForm' }
                            title = { 'Error en la url' }
                            descriptionError = { validForm.errorinstagram }
                        />
            }
            <Input
                name = "twitter" 
                typeInput = {"text"} 
                inputStyle = {'__input __input_with_icon'} 
                placeholder = {'https://www.twitter.com/usuario'}
                InputIcon = { TwitterIcon }
                value = {inputFormValues.twitter ||""}
                onChange = {handdleInputChange}/>
            {
                validForm.twitter 
                    && <AlertInForm 
                            styleAlert={ '__alert_error_inForm' }
                            title = { 'Error en la url' }
                            descriptionError = { validForm.errortwitter }
                        />
            }
            {
                isDiferent 
                    &&  <button 
                            disabled = { isLoading} 
                            onClick = { handleSaveData } 
                            className="__btn ">
                                {
                                    isLoading 
                                        ? <LoadingInComponent />
                                        :<> <IconCheck /> <p>Guardar cambios</p></>
                                }
                            </button> 
            }
        </div>
        {
            isSaved 
            && <div className = "__wrapper_saved animate__animated animate__bounce animate__fadeOut animate__delay-4s">
                    <h4>Se han guardado los cambios correctamente</h4>
                    <IconCheck />
                </div>
        }
        </>
    )
}