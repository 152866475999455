export const types = {
    openModalImage: '[ui] open-Modal-Image',
    closeModalImage: '[ui] close-Modal-Image',
    openModalSuscribe: '[ui] open-Modal-Suscribe',
    closeModalSuscribe: '[ui] close-Modal-Suscribe',
    closeAllModals: '[ui] close-All-Modals',
    uiLogout: '[ui] logout',
    loadigApp: '[ui] loadingApp',
    userLoged: '[data] userLoged',
    updateVerifier: '[data] upate-Verification',
    userLogout: '[data] user-Logout',
    updateUserLoged: '[data] userUpdate',
    associationFromRegio: '[data-asc] FromRegion',
    restAssociationFromRegio: '[data-asc] Reset-Asc-From-Regin',
    addAllMyEvents: '[ event-user ] Add-all-my-event',
    resetMyEvent: '[ event-user ] Reset-my-event',
    deleteMyEvent: '[ event-user ] Delete-my-event(s)',
    getMyEvent: '[ event-user ] Get-my-events',
    addGroupsEvent: '[ groups-event ] Add-Groups',
    resetGroups:'[ groups-event ] Reset-Groups',
    addAllFavoriteAsc: '[ favorite ] Add-all-favorite',
    addToFavorite: '[ favorite ] Add-To-favorite',
    resetFavorites: '[ favorite ] Reset-favorites',
    removeFavorite: '[ favorite ] Remove-favorite',
    updateAvatar: '[user] Update-avatar-user-loged',
    updateUserData: '[user] Update-user-data',
    addTOKEN: '[user] Add-TOKEN',
    openModalCreateEvent: '[ui] open-Modal-Create-event',
    createEvent: '[event-user] Create-Event',
    loadingInComponent: '[ui] loading-in-component',
    createMyGroup: '[ groups-event ] create-my-group',
    openModalListParticipants: '[ui] open-Modal-ListParticipants',
    openAlert: '[ui] open-Alert',
    closeAlert: '[ui] close-Alert',
    deleteGroups: '[groups-event] delete-Group',
    toggleConffetti: '[ui] toggle-Conffetti',
    registerToGroup: '[groups-event] register-To-Group',
    nextStepSuscribe:'[ui] next-step-suscribe',
    isParticipantGroup:'[ui] is-participant-group',
    addInformationGroup:'[group-visit] visited-group',
    addResponse:'[group-visit] add-response',
    addAssociationFromCountry: '[search-asc] get-Asc-From-Country',
    addPublication:'[group-visit] add-Publication'
}