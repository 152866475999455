import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

export const InfoAndTools = (typeUser) => {
    const { userLogedReducer }= useSelector(state => state ); 
    return (
        <div className ="__wrapper_column_right_grid_sources">
                <Link className="_item_sorces" to ="/questions" >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
                    <p style={{textAlign: 'center'}}>Preguntas Frecuentes</p>
                </Link>
                {
                    (userLogedReducer.typeUser === "ASC") && <Link className="_item_sorces" to ="/tools" >
                                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" /></svg>
                                                        <p style={{textAlign: 'center'}}>Herramientas Digitales</p>
                                                    </Link>
                }
                {typeUser.typeUser != 'ASC' ? 
                <Link className="_item_sorces" to={{ pathname: "https://blooders.org/" }} target="_blank" >
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="225.000000pt" height="225.000000pt" viewBox="0 0 225.000000 225.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#FF0000" stroke="none">
                    <path d="M1093 2073 c-67 -130 -136 -260 -144 -269 -5 -6 -27 -47 -49 -90 -49
                    -97 -77 -146 -146 -251 -30 -45 -54 -89 -54 -97 0 -9 -4 -16 -10 -16 -5 0 -10
                    -5 -10 -12 0 -6 -22 -45 -49 -88 -72 -111 -158 -294 -177 -375 -23 -99 -14
                    -236 21 -335 15 -41 30 -77 34 -80 3 -3 14 -22 23 -42 42 -90 172 -210 294
                    -270 105 -53 154 -63 294 -63 143 0 201 12 316 65 94 43 109 54 191 140 74 78
                    116 144 151 242 23 65 26 87 26 198 0 105 -4 136 -24 190 -30 84 -101 220
                    -211 402 -33 54 -59 100 -59 103 0 3 -16 32 -37 63 -20 31 -54 91 -76 132 -22
                    41 -60 111 -84 155 -23 44 -73 137 -109 208 -37 70 -72 127 -79 127 -7 0 -22
                    -17 -32 -37z m78 -668 c15 -27 31 -57 35 -65 5 -8 21 -37 37 -65 17 -27 41
                    -70 55 -95 13 -25 35 -63 48 -85 12 -22 49 -92 81 -155 56 -112 58 -117 58
                    -195 0 -74 -3 -85 -40 -154 -22 -40 -53 -84 -68 -97 -16 -13 -25 -24 -21 -24
                    22 0 -44 -34 -95 -50 -103 -31 -277 -22 -322 16 -8 8 -20 14 -26 14 -9 0 -30
                    22 -93 97 -36 43 -54 110 -54 203 0 94 1 96 47 188 26 51 58 110 70 130 12 20
                    29 51 37 67 8 17 21 36 28 43 6 7 12 17 12 22 0 5 20 40 44 77 24 37 51 86 60
                    108 21 49 45 77 64 73 8 -2 27 -25 43 -53z"/>
                    </g>
                    </svg>
                


                    <p style={{textAlign: 'center'}}>Blooders</p>
                </Link>
                :
                <Link className="_item_sorces" to ="/conacim" >
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="960.000000pt" height="726.000000pt" viewBox="0 0 960.000000 726.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,726.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M4052 6637 c-51 -18 -96 -37 -100 -43 -4 -6 -31 -23 -60 -38 -112
                    -57 -156 -112 -1012 -1281 -452 -616 -828 -1128 -836 -1139 -13 -15 -14 -20
                    -2 -31 7 -7 269 -191 583 -409 l570 -397 17 22 c17 20 1297 1815 1382 1938 22
                    31 45 61 51 66 7 6 38 48 68 95 31 47 69 100 84 118 15 19 30 39 32 45 2 7 7
                    14 10 17 3 3 11 12 16 22 24 40 95 137 119 163 14 15 26 32 26 36 0 11 300
                    433 311 437 5 2 9 8 9 12 0 10 219 321 233 331 4 3 9 19 10 35 l2 29 -710 2
                    -710 1 -93 -31z"/>
                    <path d="M4916 5611 c-225 -313 -409 -571 -410 -574 0 -3 382 -274 849 -602
                    468 -328 855 -602 860 -609 6 -7 278 -198 605 -424 328 -226 606 -421 617
                    -432 12 -11 24 -20 27 -20 11 0 96 -64 96 -72 0 -4 5 -8 11 -8 6 0 52 -29 104
                    -65 51 -36 96 -65 99 -65 3 0 6 306 6 680 l0 680 -80 162 c-43 90 -90 175
                    -102 190 -13 14 -354 277 -758 584 -1073 814 -1087 825 -1305 991 -110 84
                    -203 153 -206 153 -3 0 -189 -256 -413 -569z"/>
                    <path d="M1597 4294 c-4 -4 -7 -289 -7 -634 l0 -627 75 -152 c118 -238 62
                    -191 1345 -1131 602 -441 1105 -814 1117 -828 12 -15 25 -23 29 -20 3 4 183
                    267 400 585 335 493 391 579 377 588 -62 38 -1392 932 -2381 1600 -293 198
                    -554 374 -580 390 -26 17 -112 74 -192 129 -148 101 -170 113 -183 100z"/>
                    <path d="M5589 2910 c-327 -478 -773 -1132 -991 -1452 -219 -321 -398 -590
                    -398 -599 0 -9 -8 -23 -19 -30 -18 -14 -191 -261 -191 -273 0 -4 -8 -16 -18
                    -27 -11 -10 -25 -31 -32 -44 -29 -55 -38 -54 687 -55 l662 0 94 34 c223 81
                    373 198 519 407 47 68 170 243 271 389 102 146 215 308 252 360 36 52 108 156
                    160 230 51 74 127 182 168 240 41 58 102 146 136 195 34 50 89 128 122 175 33
                    47 112 162 175 257 63 95 126 185 140 200 13 15 24 35 22 45 -2 11 -232 176
                    -582 418 -319 220 -580 400 -582 400 -1 0 -269 -391 -595 -870z"/>
                    </g>
                </svg>
                    <p style={{textAlign: 'center'}}>CONACIM</p>
                </Link>
}
     </div>
    )
}
