import React from 'react'
import { Helmet } from 'react-helmet'
import { ConfigDataPrivate } from '../configUser/ConfigDataPrivate'
import { ConfigPassword } from '../configUser/ConfigPassword'
import { ConfigDataAssociation } from '../configUser/ConfigDataAssociation'
import { ConfigLocation } from '../configUser/ConfigLocation'
import { WrapperQuestions } from '../Items/WrapperQuestions'

export const QuestionScreen = () => {

    return (
        <>
            <Helmet>
               <title>Comunidav | Preguntas Frecuentes</title>
            </Helmet>
            <div className = "__wrapper_header_form" style={{alignSelf: 'center'}}>
                <h1>Preguntas Frecuentes</h1>
           </div>
            <form className="__wrapper_question animate__animated animate__bounce animate__fadeIn">
                <strong>General:</strong>
                <div style={{display: 'flex', flexDirection: 'column', width:  '100%'}}>
                    <WrapperQuestions title={'¿Cómo puedo crear una cuenta?'}>
                        <div>
                            <p style={{textAlign: 'justify'}}>Esta parte es muy sencilla, solo tienes que seleccionar tu área de preferencia (ya sea de
                                Voluntario / Donador o como una Asociación), seleccionar el botón correspondiente en la
                                página de inicio y llenar los datos solicitados. De este modo estarás creando tu perfil en base a
                                tus preferencias.</p>
                        </div>
                    </WrapperQuestions>
                    <WrapperQuestions title={'¿Cuál es la diferencia entre cuenta una de Asociación y Voluntario/ Donador?'}>
                        <div>
                            <p style={{textAlign: 'justify'}}> * Una <a style={{fontFamily: 'SF-Bold'}}> Asociación </a> pretende brindar causas o temas a solucionar o de mejora en el entorno en
                                el que están.</p>
                            <p style={{textAlign: 'justify', marginTop: '5px'}}> * Un <a style={{fontFamily: 'SF-Bold'}}> Voluntario/ Donador </a> es un individuo que busca sumarse a una causa de su interés, de
                            manera física o realizando donaciones monetarias o en especie, para apoyar y mejorar su
                            entorno.</p>
                        </div>
                    </WrapperQuestions>
                    <WrapperQuestions title = {'¿Cómo puedo cambiar mi contraseña en caso de olvidarla?'}>
                        <div>
                            <p style={{textAlign: 'justify'}}> Siguiendo estos sencillos pasos se puede llevar a cabo el cambio de la contraseña:</p>
                            <p style={{textAlign: 'justify', marginLeft: '5px', marginTop: '5px'}}> 1.- Dirigirse a la ventana para iniciar sesión.</p>
                            <p style={{textAlign: 'justify', marginLeft: '5px', marginTop: '5px'}}> 2.- Dar click al texto '¿Olvidaste tu contraseña?' llevandolo a una nueva ventana.</p>
                            <p style={{textAlign: 'justify', marginLeft: '5px', marginTop: '5px'}}> 3.- Escribir un correo electrónico para hacerle llegar un código de verificación. (Este código tiene un limite de tiempo)</p>
                            <p style={{textAlign: 'justify', marginLeft: '5px', marginTop: '5px'}}> 4.- Escribir el código que recibió.</p>
                            <p style={{textAlign: 'justify', marginLeft: '5px', marginTop: '5px'}}> 5.- Relizar el cambio de contraseña ingresando una nueva contraseña.</p>
                        </div>
                    </WrapperQuestions>
                </div>
                <strong>Asociación:</strong>
                <div style={{display: 'flex', flexDirection: 'column', width:  '100%'}}>
                    <WrapperQuestions title={'¿Para qué sirven los eventos?'}>
                        <div>
                            <p style={{textAlign: 'justify'}}>Un evento sirve para ser más específicos en una causa, la gente podrá ver y tener en mente
                                hacia dónde va su voluntariado o sus donaciones para resolver el problema o apoyar
                                específicamente a ese tema en específico.</p>
                        </div>
                    </WrapperQuestions>
                </div>
            </form>
        </>
    )
}
