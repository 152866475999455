import React from 'react'

export const ChatScreen = () => {
    return (
        <div className="__wrapper_working">
            <img src="https://media.giphy.com/media/905GG7MjDw61q/giphy.gif" alt="meme" />
            <strong>Disculpa las molestias, pero estamos trabajando para ofrecerte el mejor servicio, el chat estará disponible muy pronto...</strong>
        </div>
    )
}
