import React  from 'react'
import { CreateEvent } from '../createEvent/CreateEvent'

export const CreateEventScreen = () => {

    return (
        <CreateEvent />
    )
    
}
