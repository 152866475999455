import React from 'react'

export const InstagramIcon = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M15 12a3 3 0 11-3-3 3.01 3.01 0 013 3zm6.375-4.125v8.25a5.25 5.25 0 01-5.25 5.25h-8.25a5.25 5.25 0 01-5.25-5.25v-8.25a5.25 5.25 0 015.25-5.25h8.25a5.25 5.25 0 015.25 5.25zM16.5 12a4.5 4.5 0 10-9 0 4.5 4.5 0 009 0zM18 7.125a1.125 1.125 0 10-2.25 0 1.125 1.125 0 002.25 0z"
          ></path>
        </svg>
      );
}
