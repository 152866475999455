import React from 'react'
import { IconArrowDown } from '../iconos/IconArrowDown'

export const WrapperQuestions = ({ children, title }) => {
    return (
        <details className={'__wrapper_question_details'}>
            <summary>
                <IconArrowDown />
                { title }
            </summary>
            { children }
        </details>
    )
}