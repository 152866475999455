export const BADGE_COLORS = {
    'ASC': 'badge_asc',
    'VOL': 'badge_vol',
    'Bienestar': 'cat-bienestar',
    'Salud': 'cat-salud',
    'Personas Mayores':'cat-PM',
    'Medioambientales': 'cat-Ma',
    'Culturales':'cat-Cul',
    'Deportivas':'cat-Dep',
    'Animalistas':'cat-Ani',
}