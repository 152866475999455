import React from "react";
import { Helmet } from 'react-helmet'
import CostosImg from '../../assets/costos.jpg'

export const ConacimScreen = () =>{
    return (
        <>
            <Helmet>
               <title>Comunidav | CONACIM</title>
            </Helmet>
            <div className = "__wrapper_header_conacim">
                <h1>CONACIM</h1>
           </div>
            <div className = "__wrapper_content_info">
                <p>Hemos creado un paquete de servicios profesionales para cubrir todas las necesidades que tienen las Fundaciones
                    en el país, brindando Abogado, Contador y Gestor en un solo lugar por un increíble
                    precio, sin importar en qué lugar de la República Mexicana se encuentren.</p>
                
                <div style={{backgroundColor: '#4aa9a5', alignSelf: 'start', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className = "__wrapper_content_card">
                        <h2>Servicios Profesionales:</h2>
                        <p style={{marginTop: '10px' , textAlign: 'justify'}}>En CONACIM contamos con especialistas en materia
                            de Organizaciones de la Sociedad Civil, el Paquete incluye 3 áreas de servicio:</p>
                       <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                       <div>
                       <ul>
                        <li type='disc'>Servicios contables </li>
                        <li type='disc'>Gestión de trámites </li>
                        <li type='disc'>Servicios legales </li>
                        </ul> 
                        </div>
                        </div>
                    </div>
                    <img style={{alignSelf: 'center', marginLeft: '10px', marginTop: '20px', objectFit: 'contain', width: '30%', height: '50%'}} src="https://static.wixstatic.com/media/caa8fe_3914e941b5d14b358edb5219083852fe~mv2.jpg/v1/fit/w_2500,h_1330,al_c/caa8fe_3914e941b5d14b358edb5219083852fe~mv2.jpg" alt={ 'Logo de Wix' } /> 
                </div>
                <div style={{backgroundColor: '#4aa9a5', alignSelf: 'end', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'contain', width: '30%', height: '50%'}} src="https://seminarioiiuntref.files.wordpress.com/2019/11/the-top-3-myths-about-legal-automation.jpg?w=1200" alt={ 'Logo de GoDaddy' }/> 
                    <div className = "__wrapper_content_card">
                        <h2 style={{textAlign:'center'}}>Servicios legales:</h2>
                        <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <ul>
                                <li type='disc'>Litigio</li>
                                <li type='disc'>Asesoría</li>
                                <li type='disc'>Auditoría</li>
                                <li type='disc'>Contratos</li>
                                <li type='disc'>Diagnósticos</li>
                                <li type='disc'>Acreditaciones</li>
                                <li type='disc'>Aviso de Privacidad</li>
                                <li type='disc'>Contratación de Personal</li>
                                <li type='disc'>Informe anual de actividades</li>
                                <li type='disc'>Entre otros...</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div style={{backgroundColor: '#4aa9a5', alignSelf: 'start', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className = "__wrapper_content_card">
                        <h2>Servicios contables:</h2>
                        <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                           <ul>
                            <li type='disc'>Diagnóstico</li>
                            <li type='disc'>Auditorias </li>
                            <li type='disc'>Cédula de activos</li>
                            <li type='disc'>Declaración fiscal </li>
                            <li type='disc'>Regularización fiscal </li>
                            <li type='disc'>Requerimientos SAT </li>
                            <li type='disc'>Informe Ley Antilavado </li>
                            <li type='disc'>Constancia fiscal y 32D </li>
                            <li type='disc'>Recurso de revocación </li>
                            <li type='disc'>Recurso de inconformidad </li>
                            <li type='disc'>Declaración de transparencia </li>
                            <li type='disc'>Estados y balances financieros </li>
                            <li type='disc'>Facturaciób y recivos deducibles </li>
                            <li type='disc'>Declaración de información múltiple </li>
                            <li type='disc'>Entre otros...</li>
                            </ul> 
                        </div>

                    </div>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'contain', width: '40%', height: '40%'}} src="https://previews.123rf.com/images/valery_potapova/valery_potapova1506/valery_potapova150600051/40841997-informe-de-negocios-taza-de-caf%C3%A9-en-el-documento-contabilidad-azul-tonificado.jpg" alt={ 'Logo de Canva' }/> 
                </div>
                <div style={{backgroundColor: '#4aa9a5', alignSelf: 'end', width: '50%', height: '2px', marginTop: '10px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'fill', width: '50%', height: '70%'}} src="https://www.soyconta.com/wp-content/uploads/2022/11/blgsc-14n-680x303.jpg" alt={ 'Logo de Pixlr' }/> 
                    <div className = "__wrapper_content_card">
                        <h2 style={{textAlign:'center'}}>Servicios de gestión:</h2>
                        <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <ul>
                                <li type='disc'>Cluni </li>
                                <li type='disc'>Citas SAT </li>
                                <li type='disc'>NOM 035</li>
                                <li type='disc'>Carta de acreditación </li>
                                <li type='disc'>Autorización Donataria Nacional</li>
                                <li type='disc'>Autorización Donataria Internacional</li>
                                <li type='disc'>Permisos y avisos de funcionamiento</li>
                                <li type='disc'>Entre otros...</li>
                            </ul>
                        </div>
                        
                    </div>
                    
                </div>
                <div style={{backgroundColor: '#4aa9a5', alignSelf: 'start', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                        <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'contain', width: '100%', height: '100%'}} src={CostosImg} alt={ 'Logo de Canva' }/> 
                </div>

                <div style={{backgroundColor: '#4aa9a5', alignSelf: 'end', width: '50%', height: '2px', marginTop: '10px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'fill', width: '30%', height: '40%'}} src="https://cdn-icons-png.flaticon.com/512/59/59045.png" alt={ 'Logo de Pixlr' }/> 
                    <div className = "__wrapper_content_card">
                        <h2 style={{textAlign:'center'}}>Solicita una asesoría sin costo</h2>
                        
                            <p>WhatsApp: 55 4230 5972</p>
                            <p>servicios@conacim.org </p>
                            <p>www.conacim.org</p>
                        
                        
                    </div>
                    
                </div>
            </div>

            
            

        </>
    )

}