import React from 'react'
import { Helmet } from 'react-helmet'

export const ToolScreen = () => {

    return (
        <>
            <Helmet>
               <title>Comunidav | Herramientas Digitales</title>
            </Helmet>
            <div className = "__wrapper_header_info">
                <h1>Herramientas Digitales</h1>
           </div>
            <div className = "__wrapper_content_info">
                <p>Hola, en esta pestaña podrás encontrar algunas herramientas para optimizar el manejo de tus
                    redes sociales, el diseño de tus imágenes, creación de páginas de internet. Entre otras cosas.</p>
                <p style={{alignSelf: 'start'}}>¡Esperamos que esta información sea de tu utilidad!</p>
                <div style={{backgroundColor: '#f99479', alignSelf: 'start', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className = "__wrapper_content_card">
                        <h2>Desarrollo de Paginas Web:</h2>
                        <p style={{marginTop: '10px' , textAlign: 'justify'}}>Como principal herramienta recomendamos utilizar WIX o Shopify,
                         en el caso de las asociaciones WIX es una herramienta muy amigable 
                         y te va guiando paso a paso para que puedas crear tu propia pagina,
                         comprar tu dominio, entre otras cosas y también cuentan con un muy 
                         buen servicio de atención a clientes.</p>

                        <p>En caso de más información puedes consultarlo en el siguiente <a target='_blank' href="https://es.wix.com/" style={{color: '#f65c32'}}> enlace </a> </p>
                    </div>
                    <img style={{alignSelf: 'center', marginLeft: '10px', marginTop: '20px', objectFit: 'contain', width: '30%', height: '50%'}} src="https://1000marcas.net/wp-content/uploads/2022/05/Wix-Logo-2012.png" alt={ 'Logo de Wix' } /> 
                </div>
                <div style={{backgroundColor: '#f99479', alignSelf: 'end', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'contain', width: '30%', height: '50%'}} src="https://1000marcas.net/wp-content/uploads/2021/05/GoDaddy-Logo.png" alt={ 'Logo de GoDaddy' }/> 
                    <div className = "__wrapper_content_card">
                        <h2>Correo Empresarial:</h2>
                        <p style={{marginTop: '10px', textAlign: 'justify'}}>En caso de buscar un correo empresarial (Ej: info@asociacion.org )la
                            opción más barata y amigable que hemos encontrado es GoDaddy, en
                            esta plataforma podrás comprar tus dominios y también conectarlos a
                            tus redes sociales. Es una opción confiable y cuenta con un excelente
                            servicio al cliente.</p>

                        <p>En caso de más información puedes consultarlo en el siguiente <a target='_blank' href="https://www.godaddy.com/es" style={{color: '#f65c32'}}> enlace </a> </p>
                    </div>
                    
                </div>
                <div style={{backgroundColor: '#f99479', alignSelf: 'start', width: '50%', height: '2px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className = "__wrapper_content_card">
                        <h2>Diseños de imagen:</h2>
                        <p style={{marginTop: '10px', textAlign: 'justify'}}>Herramienta gratuita para poder diseñar de manera rápida y sencilla
                            Flyers, logos, imágenes, presentaciones para el uso de su contenido. Esta
                            es una herramienta muy sencilla de utilizar y muy eficiente, cuenta con
                            una versión premium para un uso más profesional.
                            </p>

                        <p>En caso de más información puedes consultarlo en el siguiente <a target='_blank' href="https://www.canva.com/" style={{color: '#f65c32'}}> enlace </a> </p>
                    </div>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'contain', width: '20%', height: '50%'}} src="https://logodownload.org/wp-content/uploads/2020/11/canva-logo-1.png" alt={ 'Logo de Canva' }/> 
                </div>
                <div style={{backgroundColor: '#f99479', alignSelf: 'end', width: '50%', height: '2px', marginTop: '10px'}}/>
                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
                    <img style={{alignSelf: 'center', marginLeft: '10px', objectFit: 'fill', width: '30%', height: '50%'}} src="https://consejoderedaccion.org/images/pix-1.jpg" alt={ 'Logo de Pixlr' }/> 
                    <div className = "__wrapper_content_card">
                        <h2>Edición de Imagen:</h2>
                        <p style={{marginTop: '10px', textAlign: 'justify'}}>Herramienta gratuita para poder editar de manera rápida y sencilla
                            imágenes para el uso de su contenido. Esta es una herramienta muy
                            sencilla de utilizar y muy eficiente, cuenta con una versión premium
                            para un uso más profesional.</p>

                        <p>En caso de más información puedes consultarlo en el siguiente <a target='_blank' href="https://pixlr.com/es/" style={{color: '#f65c32'}}> enlace </a> </p>
                    </div>
                    
                </div>
            </div>


        </>
    )
}
